<template>
  <b-table
    ref="selectableTable"
    class="col-12"
    data-cy="ngotiation-table"
    :items="listTransactions"
    :fields="fields"
    responsive="sm"
    :busy="loading"
    striped
    hover
  >
    <template v-slot:table-busy>
      <div class="text-center text-dark my-2">
        <b-spinner class="align-middle" />
        <strong>Carregando...</strong>
      </div>
    </template>

    <template v-slot:cell(kind)="data">
      <big-badge
        :variant="setKindFormat(data.item.kind)"
      >
        {{ setKindLabel(data.item.kind) }}
      </big-badge>
    </template>

    <template v-slot:cell(amount)="data">
      {{ setPriceFormat($t('payments.labels.currency'), data.item.amount) }}
    </template>

    <template v-slot:cell(overdue_limit)="data">
      <span class="text-uppercase">
        {{ formatDateUtc(data.item.overdue_limit) | moment('DD/MM/YYYY') }}
      </span>
    </template>

    <template v-slot:cell(internal_comments)="data">
      <span class="text-uppercase">
        {{ data.item.internal_comments || ' - ' }}
      </span>
    </template>

    <template v-slot:cell(show_details)="row">
      <b-button
        variant="primary"
        size="sm"
        class="mr-2"
        @click="showDetails(row)"
      >
        {{ $t('payments.buttons.details') }}
        <i class="fa fa-eye" />
      </b-button>
    </template>

    <template v-slot:row-details="row">
      <driver-negotiation-list
        :key="row.index"
        :transactionsChildren="row.item.childrens" 
      />
    </template>
  </b-table>
</template>

<script>
import TRANSACTIONS_NEGOTIATED from '@graphql/negotiation/queries/negotiated.gql';
import TRANSACTIONS_BY_PARENT_ID from '@graphql/negotiation/queries/transactionsByParentId.gql';
import { formatPrice } from '@utils/numbers';
import { formatStatus, formatKind, parseKind } from '@utils/wallet';
import BigBadge from '@components/shared/big-badge';
import { makeQuery } from '@graphql/middleware'
export default {
    name: 'DriverNegotiationTable',
    components: {
      BigBadge,
      DriverNegotiationList: () => import('@components/drivers/payment-info/driver-negotiation-list')
    },
     props: {
      driverId: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        fields: [
          { key: 'kind', label: this.$t('payments.tables.kind'), sortable: true },
          { key: 'description', label: this.$t('payments.tables.description') },
          { key: 'amount', label: this.$t('payments.tables.amount')},
          { key: 'internal_comments', label: this.$t('payments.tables.internal_comments') },
          { key: 'overdue_limit', label: this.$t('payments.tables.overdue_limit') },
          { key: 'show_details', label: this.$t('payments.tables.actions') },
        ],
        listTransactions: [],
        loading: true
      }
    },
    mounted () {
      this.getTransactions()
    },
    methods: {
      setPriceFormat(_currency, _value) {
        return formatPrice(_currency, _value);
      },
      setKindLabel: parseKind,
      setKindFormat: formatKind,
      getTransactions () {
        makeQuery(
          TRANSACTIONS_NEGOTIATED,
          { driver: this.driverId }
        )
        .then((res) => {
          this.loading = false
          this.listTransactions = res.data.getTransactionsNegotiated.items.map(e => {
            return {
              ...e,
              childrens: []
            }
          })
        })
      },
      showDetails (row) {
        row.toggleDetails();
        let childrensTransactions = this.listTransactions[row.index]
        if (!row.detailsShowing && childrensTransactions.childrens.length === 0) {
          makeQuery(
            TRANSACTIONS_BY_PARENT_ID,
            { parent_id: row.item.id }
          )
          .then((res) => {
            childrensTransactions.childrens = res.data.getTransactionsByParentId.items
          })
        }
      },
      formatDateUtc (date) {
        return this.$moment.utc(date)
      }
    }
}
</script>

<style scoped>
.icon-custom-size {
  font-size: 18px;
}
</style>