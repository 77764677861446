var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-table", {
    ref: "selectableTable",
    staticClass: "col-12",
    attrs: {
      "data-cy": "ngotiation-table",
      items: _vm.listTransactions,
      fields: _vm.fields,
      responsive: "sm",
      busy: _vm.loading,
      striped: "",
      hover: "",
    },
    scopedSlots: _vm._u([
      {
        key: "table-busy",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "text-center text-dark my-2" },
              [
                _c("b-spinner", { staticClass: "align-middle" }),
                _c("strong", [_vm._v("Carregando...")]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "cell(kind)",
        fn: function (data) {
          return [
            _c(
              "big-badge",
              { attrs: { variant: _vm.setKindFormat(data.item.kind) } },
              [_vm._v(" " + _vm._s(_vm.setKindLabel(data.item.kind)) + " ")]
            ),
          ]
        },
      },
      {
        key: "cell(amount)",
        fn: function (data) {
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm.setPriceFormat(
                    _vm.$t("payments.labels.currency"),
                    data.item.amount
                  )
                ) +
                " "
            ),
          ]
        },
      },
      {
        key: "cell(overdue_limit)",
        fn: function (data) {
          return [
            _c("span", { staticClass: "text-uppercase" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(
                      _vm.formatDateUtc(data.item.overdue_limit),
                      "DD/MM/YYYY"
                    )
                  ) +
                  " "
              ),
            ]),
          ]
        },
      },
      {
        key: "cell(internal_comments)",
        fn: function (data) {
          return [
            _c("span", { staticClass: "text-uppercase" }, [
              _vm._v(" " + _vm._s(data.item.internal_comments || " - ") + " "),
            ]),
          ]
        },
      },
      {
        key: "cell(show_details)",
        fn: function (row) {
          return [
            _c(
              "b-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "primary", size: "sm" },
                on: {
                  click: function ($event) {
                    return _vm.showDetails(row)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("payments.buttons.details")) + " "),
                _c("i", { staticClass: "fa fa-eye" }),
              ]
            ),
          ]
        },
      },
      {
        key: "row-details",
        fn: function (row) {
          return [
            _c("driver-negotiation-list", {
              key: row.index,
              attrs: { transactionsChildren: row.item.childrens },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }